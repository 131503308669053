import "../shared/style.css";
import "../shared/theme.css";

const About = () => {
  return (
    <>
      <div class="section section-pad bg-grey">
        <div class="container">
          <div class="section-head text-center">
            <div class="row">
              <div class="col-md-12 col-sm-12 ">
                <h2 class="heading-section">About Us</h2>
                <p class="custom-p">
                  RELO thrives to become a miner's dream located in the North America. 
                  We have already relocated over 20MW of machines to our enterprise site.
                  Mining is made simple with RELO. We offer mining
                  properties, dependable software, mining pool options, mining
                  farm hosting and administration services, both remotely and
                  on-site. Additionally, our skilled staff provides real-time
                  equipment monitoring, troubleshooting, and miner optimization
                  to our customers across North America.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-pad ">
      <div class="contact-form-wrap container">
            <p class="form-title">Complete and submit the form below to get in touch</p>
            <form class=""><div class="g-4 row"><div class="col-md-6">
              <input placeholder="Full Name *" required="" type="text" class="form-control"/>
            </div>
              <div class="col-md-6">
                <input placeholder="Email *" required="" type="email" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <input placeholder="Phone Number *" required="" type="number" class="form-control"/>
                      </div>
                      <div class="col-md-6">
                        <input placeholder="Subject *" required="" type="text" class="form-control"/>
                          </div><div class="col-md-12">
                            <textarea placeholder="Messages *" required="" rows="8" class="form-control">
                              </textarea></div><div class="col-md-12"><button class="btn submit-btn">Submit</button></div></div></form></div>
     </div>
      {/* <div class="section section-pad ">
        <div class="container">
          <div class="section-head">
            <div class="row text-center">
              <div
                class="
                col-md-6
                col-md-offset-3
                col-md-offset-3
                col-sm-8
                col-sm-offset-2
              "
              >
                <h2 class="heading-section">
                  Leadership and Board of Directors
                </h2>
              </div>
            </div>
          </div>
          <div class="gaps size-3x"></div>
          <div class="row text-center">
            <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/jayHao.JPG"
                    alt="team-thumb"
                  />
  
                </div>
                <div class="team-info">
                  <h5 class="team-name">Jay Hao</h5>
                  <span class="team-title">Chairman and Founder</span>
                </div>

              </div>
            </div>
            <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/parth.jpg"
                    alt="team-thumb"
                  />

                </div>
                <div class="team-info">
                  <h5 class="team-name">Parth Patel</h5>
                  <span class="team-title">CEO and Founder</span>
                </div>

              </div>
            </div>

            <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/deepak.png"
                    alt="team-thumb"
                  />

                </div>
                <div class="team-info">
                  <h5 class="team-name">Sahil Sharma</h5>
                  <span class="team-title">VP of Outreach</span>
                </div>

              </div>
            </div>

            <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img src="images/team/don.jpeg" alt="team-thumb" />
                </div>
                <div class="team-info">
                  <h5 class="team-name">Vivek Patel</h5>
                  <span class="team-title">VP of Operation</span>
                </div>
   
              </div>
            </div>
          </div>

          <div class="gaps size-3x"></div>
          <div class="row text-center">
            <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/frank.jpg"
                    alt="team-thumb"
                  />
  
                </div>
                <div class="team-info">
                  <h5 class="team-name">Lenroy Bashford</h5>
                  <span class="team-title">Senior VP of Finance</span>
                </div>

              </div>
            </div>
            <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/anil.jpg"
                    alt="team-thumb"
                  />

                </div>
                <div class="team-info">
                  <h5 class="team-name">David Cohen</h5>
                  <span class="team-title">Legal</span>
                </div>

              </div>
            </div>

            

          </div>
        </div>
      </div> */}
    </>
  );
};

export default About;