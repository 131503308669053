import "../shared/style.css";
import "../shared/theme.css";

function Footer() {
  return (
    <>
      <div class="footer-section section section-pad-md light bg-footer">
        <div class="imagebg footerBg">
          <img src="images/footerBackground.svg" alt="footer-bg" />
        </div>
        <div class="container" style={{width: '100%'}}>
          <div class="row">
            <div class="col-md-8 col-sm-6 wgs-box res-m-bttm">
              {/* <!-- Each Widget --> */}
              <div class="p-20">
                {/* <img width={"6%"} src="images/reloIcon.jpeg" alt="footer-bg" /> */}
                <p style={{padding: '20px'}}>
                  RELO is a Bitcoin-first company on a mission to support the
                  decentralized growth of hashrate and strengthen network
                  security by helping more people learn, explore and mine
                  Bitcoin.
                </p>
              </div>
              {/* <!-- End Widget --> */}
            </div>
            <div class="col-md-2 col-sm-6 wgs-box res-m-bttm">
              <div class="wgs-footer wgs-menu">
                <h5 class="wgs-title ucap">COMPANY</h5>
                <div class="wgs-content">
                  <ul class="menu" style={{listStyleType:'none', color: 'white'}}>
                    <li>
                      <a href="/about">About Us</a>
                    </li>
                    {/* <li>
                      <a href="/privacy">Privacy</a>
                    </li> */}
                    {/* <li>
                      <a href="/termsOfService">Terms Of Service</a>
                    </li> */}
                    {/* <li>
                      <a href="/event">Events</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* next section */}
      <div class="google-map-section section-inner-gap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="gmap-box"></div>
          </div>
        </div>
      </div>
    </div>
    {/* copyright */}
    <div class="copyright light">
      <div class="container">
        <div class="row">
          <div class="site-copy col-sm-7">
            <p class="copyright-text">
              &copy; {(new Date().getFullYear())} <a href="/">RELO</a> – All Rights Reserved
            </p>
          </div>
          <div class="col-sm-5 text-right mobile-left">
            <ul class="social">
              {/* <li><a href="/termsOfService">Terms Of Service</a></li> */}
						<li><a href="/privacy">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;
