import { useState, useEffect } from "react";
import "../shared/style.css"
import "../shared/theme.css"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";

function Homepage() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }



    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, []);

  return (
    <>
      <div id="header" className="banner-md light bannerTheme">
        <div className="videoChange imagebg-bottom">
          <video
            className="videoChange"
            autoplay="autoplay"
            webkit-playsinline
            playsinline
            muted="muted"
            loop="loop"
          >
            <source height src="videos/headerVideo.mp4" type="video/mp4" />
          </video>
          <div className="overlay"></div>
        </div>
        <div className="headerContainer">
          <h1 className="customHeader" style={{ color: "white" }}>
            We Know Crypto Mining Infrastructure
          </h1>
          <h3 style={{ fontSize: '1.5em', color: 'white' }}>
            Creating the crypto universe
          </h3>
        </div>
      </div>
      <div className="features-box section section-pad CREM cremDescription">
        <div className="container">
          <div className="cremDescriptionHeader">
            <img width={"18%"} src="images/reloIcon.jpeg" />
            <h2 style={{color:'#0f152b'}} className=" ucap">Bringing it all together.</h2>
            <p className="cremtexDescription custom-p">
              RELO finds facilities for miners to enjoy a superior
              mining experience. We work with world-class infrastructure, secure
              hosting conditions, and crucial services that power the future of
              crypto mining!
            </p>
          </div>
          
          {/* <div className="row text-center cremBoxHeader">
            <div className="col-md-3 col-sm-6 res-m-bttm">
              <a href="#coHosting">
                <div className="box box-small-dot">
                  <h1 className="customFont">R</h1>
                 
                </div>
              </a>
            </div>
            <div className="col-md-3 col-sm-6 res-m-bttm">
              <a href="#realEstate">
                <div className="box box-small-dot">
                  <h1 className="customFont">E</h1>
                 
                </div>
              </a>
            </div>
            <div className="col-md-3 col-sm-6 res-m-bttm">
              <a href="#energy">
                <div className="box box-small-dot">
                  <h1 className="customFont">L</h1>
                 
                </div>
              </a>
            </div>
            <div className="col-md-3 col-sm-6 res-m-bttm ">
              <a href="#mining">
                <div className="box box-small-dot">
                  <h1 className="customFont">O</h1>
                 
                </div>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
    //   <div className="section mapPad bg-grey">
    //     <div className="container">
    //       <div className="section-head">
    //         <div className="row text-center">
    //           <div className="col-md-12">
    //             <h3 style={{paddingBottom: '50px' }} className="heading-section ucap">
    //               The best mining infrastructure in texas
    //             </h3>
    //             <img width="450" height="" src="images/texasMap.svg" />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div
    //     className="section section-pad cta-section light  dark-filter"
    //     style={{backgroundColor: '#009cfe'}}
    //   >
    //     <div className="container">
    //       <div className="row text-center">
    //         <div className="col-md-8 col-md-offset-2">
    //           <h3>
    //             <i>
    //               "Blockchain is a booming industry that Texas needs to be
    //               involved in."
    //             </i>{" "}
    //           </h3>
    //           <h5>
    //             <i> ~Greg Abbott</i>{" "}
    //           </h5>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="features-box section featuresCustom">
    //     {/* <!-- C in RELO for Co hosting --> */}
    //     <div className=" row  p-20 custom-box ">
    //       <div id="coHosting" className="col-md-2 col-md-offset-2   center p-20">
    //         <h1 className="customFont">C</h1>
    //         <h3 className="blueFont ">CO-HOSTING</h3>
    //       </div>
    //       <div className="col-md-5  ">

    //            <img className="customImage" src="/images/serverRoom.png" alt="bg" />
    //         <p className="custom-p">
    //           Customized hosting and infrastructure solutions for large scale
    //           miners pouring into Texas from around the world. Built for
    //           scalability, sustainability and above all security. RELO offers
    //           attractive payment structures to establish trust and create long
    //           term relationships with miners.
    //         </p>
    //         <div className={windowDimensions.width>480?"row":"row center"}>
    //           <div className="col-md-3 col-sm-6  res-m-bttm box">
    //             <img src="images/sec.svg" alt="box-icon" className="box-icon " />
    //             <h6 className="ucap">SECURE</h6>
    //           </div>

    //           <div className="col-md-3 col-sm-6   res-m-bttm box">
    //             <img src="images/reli.svg" alt="box-icon" className="box-icon" />

    //             <h6 className="ucap">Reliable</h6>
    //           </div>

    //           <div className="col-md-3 col-sm-6 res-m-bttm box">
    //             <img src="images/equi.svg" alt="box-icon" className="box-icon" />

    //             <h6 className="ucap">EQUIPMENT UPKEEP</h6>
    //           </div>

    //           <div className="col-md-3 col-sm-6 res-m-bttm box ">
    //             <img src="images/cust.svg" alt="box-icon" className="box-icon" />

    //             <h6 className="ucap">Customer Service</h6>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     {/* Realestate */}

    //     <div className=" row  p-20 custom-box ">
    //       <div id="realEstate" className="col-md-2 col-md-offset-2   center p-20">
    //         <h1 className="customFont">R</h1>
    //         <h3 className="blueFont ">REAL ESTATE </h3>
    //       </div>
    //       <div className="col-md-5" style={{marginBottom: '30px'}}>

    //            <img
    //             src="images/landTexas.png"
    //             alt="box-icon"
    //             className="customImage"
    //           />
    //         <p className="custom-p">
    //           Real estate is at the foundation of cryptomining. RELO sources
    //           prime-space and energy-accessible properties from 235 acres to
    //           140,000 acres all across Texas. Large industrial infrastructure
    //           that can be easily adaptable to mining needs and proximal to an
    //           affordable power source.
    //         </p>
    //         {/* <!-- <ul style="margin-left:40px; margin-bottom: 30px;">
    //             <li>
    //                Large industrial infrastructure that can be easily
    //               repurposed.
    //             </li>

    //             <li>Proximity to an affordable and reliable power source.</li>

    //             <li>Facilities located in best city zoned areas</li>
    //           </ul> --> */}
    //       </div>
    //     </div>
    //     {/* <!-- E in RELO for Energy --> */}

    //       <div

    //         className=" row  p-20 custom-box  "
    //       >
    //         <div  id="energy" className="col-md-2 col-md-offset-2   center p-20">
    //           <h1 className="customFont">E</h1>
    //           <h3 className="blueFont ">ENERGY</h3>
    //         </div>
    //         <div className="col-md-5 ">
    //           {/* <picture>
    //             <source type="image/webp" srcset="/images/energy.webp">
    //             <source type="image/png" srcset="/images/energy.png">

    //           </picture> */}
    //            <img
    //             src="images/energy.png"
    //             alt="box-icon"
    //             className="customImage"
    //           />
    //           <p className="custom-p">
    //             Texas is a powerhouse of abundant sources of energy. RELO collaborates with multi sector energy companies to supply clean and sustainable energy to miners within our network. We strive to mine bitcoin and other cryptocurrencies with the greenest energy and help reduce greenhouse gas emissions proactively with our energy providers.
    //           </p>
    //           <div className={windowDimensions.width>480?"row":"row center"}>
    //             <div className="col-md-3 col-sm-6 res-m-bttm box">
    //               <div >

    //                   <img
    //                   src="images/nat.svg"
    //                   alt="box-icon"
    //                   className="box-icon"
    //                 />

    //                 <h6 className="ucap">Clean Energy</h6>
    //               </div>
    //             </div>
    //             <div className="col-md-3 col-sm-6 res-m-bttm box">
    //               <div >

    //                   <img
    //                   style={{alignSelf: 'center'}}
    //                   src="images/win.svg"
    //                   alt="box-icon"
    //                   className="box-icon"
    //                 />

    //                 <h6  className="ucap">Wind</h6>
    //               </div>
    //             </div>
    //             <div className="col-md-3 col-sm-6 res-m-bttm box">
    //               <div>

    //                   <img
    //                   src="images/sol.svg"
    //                   alt="box-icon"
    //                   className="box-icon"
    //                 />


    //                 <h6  className="ucap">solar</h6>
    //               </div>
    //             </div>

    //             <div className="col-md-3 col-sm-6 res-m-bttm box">
    //               <div>

    //                   <img
    //                   src="images/geo.svg"
    //                   alt="box-icon"
    //                   className="box-icon"
    //                 />


    //                 <h6 className="ucap">Geo-Thermal</h6>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       {/* <!-- The M in RELO is for mining section --> */}
    //       <div

    //         className=" row p-20 custom-box "
    //       >
    //         <div id="mining" className="col-md-2 col-md-offset-2   center p-20">
    //           <h1 className="customFont">M</h1>
    //           <h3 className="blueFont ">MINING</h3>
    //         </div>
    //         <div className="col-md-5 ">

    //            <img
    //             src="images/bitcoinBlue.png"
    //             alt="box-icon"
    //             className="customImage"
    //           />
    //           <p className="custom-p">
    //             We relocate medium to large operations from all parts of the world and help them settle in Texas.  Our operation streamlines the process to get all our miners up and running as soon a shipment arrives at a port, within 30 days or less.

    //           </p>
    //         </div>
    //       </div>

    //       <div className="row"></div>
    //       {/* <!-- Tex in RELO for Texas --> */}

    //       <div id="texas" className="textOverImageContainer">
    //         <div className="whyTexas">
    //         <img style={{width: '100%'}}  src="images/texasLandscape.png" />
    //         <div className="overlay">
    //         <div className="col centered  ">
    //         <h1 className="overlayText" >Why Texas</h1>
    //         <p  className="overlayText">
    //           Texas boasts some of the lowest energy prices in the world. It is famed for its southern hospitality. It has emerged as a visionary leader poised to usher the future of crypto and blockchain into mainstream America. RELO makes mining simple and efficient. By mining high-tech PoS PoW PoC cryptocurrencies, RELO provides services that create value for generations to come.

    //         </p>



    //       </div>

    //     </div>

    //     </div>

    //   </div>
    //   <div id="contact" className="section section-pad">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-md-12 res-m-bttm">
    //         <div className="contact-information">
    //           <h4>Contact Information</h4>
    //           <div className="row">
    //             <div className="col-xs-12">
    //               <iframe
    //                 id="gmap_canvas"
    //                 style={{minHeight: '50vh', minWidth: '100%'}}
    //                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.2631917641434!2d-95.62527218532966!3d29.596034382047634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640e695c650ec77%3A0xc5414348d66e1793!2s2150%20Town%20Square%20Pl%20%23200%2C%20Sugar%20Land%2C%20TX%2077479%2C%20USA!5e0!3m2!1sen!2sin!4v1616562742723!5m2!1sen!2sin"
    //               ></iframe>
    //             </div>
    //           </div>
    //           <br />
    //           <br />
    //           <div className="row">
    //             <div className="col-sm-4 res-m-bttm">
    //               <div className="contact-entry">
    //                 <h6>Address</h6>
    //                
    //               </div>
    //             </div>
    //             <div className="col-sm-4 res-m-bttm">
    //               <div className="contact-entry">
    //                 <h6>Contact Number</h6>
    //                 <p>Mobile: 281-605-9553</p>
    //               </div>
    //             </div>
    //             <div className="col-sm-4 res-m-bttm">
    //               <div className="contact-entry">
    //                 <h6>Office Hours</h6>
    //                 <p>Monday - Friday<br />8:30am - 5:00pm</p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="contact-form">
    //           <p>Complete and submit the form below</p>
    //           <form
    //             id="contact-form"
    //             className="form-message"
    //             action="form/contact.php"
    //             method="post"
    //           >
    //             <div className="form-results"></div>
    //             <div className="form-group row">
    //               <div className="form-field col-sm-6 form-m-bttm">
    //                 <input
    //                   name="contact-name"
    //                   type="text"
    //                   placeholder="Full Name *"
    //                   className="form-control required"
    //                 />
    //               </div>
    //               <div className="form-field col-sm-6">
    //                 <input
    //                   name="contact-email"
    //                   type="email"
    //                   placeholder="Email *"
    //                   className="form-control required email"
    //                 />
    //               </div>
    //             </div>
    //             <div className="form-group row">
    //               <div className="form-field col-sm-6 form-m-bttm">
    //                 <input
    //                   name="contact-phone"
    //                   type="text"
    //                   placeholder="Phone Number *"
    //                   className="form-control required"
    //                 />
    //               </div>
    //               <div className="form-field col-sm-6">
    //                 <input
    //                   name="contact-subject"
    //                   type="text"
    //                   placeholder="Subject *"
    //                   className="form-control required"
    //                 />
    //               </div>
    //             </div>
    //             <div className="form-group row">
    //               <div className="form-field col-md-12">
    //                 <textarea
    //                   name="contact-message"
    //                   placeholder="Messages *"
    //                   className="txtarea form-control required"
    //                 ></textarea>
    //               </div>
    //             </div>
    //             <input
    //               type="text"
    //               className="hidden"
    //               name="form-anti-honeypot"
    //               value=""
    //             />
    //             <button type="submit" className="btn btn-alt">Submit</button>
    //           </form>
    //         </div>
    //       </div>

    //     </div>
    //   </div>

    // </div>

    //   <div className="google-map-section section-inner-gap">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-12">
    //         <div className="gmap-box"></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    //   </div>

  );
}

export default Homepage;
