
// import './App.css';
import Homepage from './component/Homepage';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import Event from './component/Event';
import About from './component/About';
import Sites from './component/Sites'
import PrivacyPolicy from './component/PrivacyPolicy';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ESG from './component/ESG';



function App() {
  return (
    <div >
      <Navbar/>
      


       {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
          
          <Route path="/*" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          {/* <Route path="/sites" element={<Sites />} /> */}
          <Route path="/event" element={<Event />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/esg" element={<ESG/>} />
            {/* <Route path="/about" exact element={<ViewFlatDeals />} />
            <Route path="/events" element={<Homepage />} /> */}
            
          </Routes>
        
      {/* </Router> */}

      <Footer/>
    </div>
  );
}

export default App;
