import { useState,useEffect } from "react";
function Navbar() {

  const [hasFixed,setHasFixed] =  useState(true);
  const [isCollapse,setIsCollapse] = useState(true);
  
useEffect(()=>{
	// Sticky
  
  window.onscroll= ()=>{
    if(window.pageYOffset === 0){
   setHasFixed(!hasFixed)
  }else{
    setHasFixed(false)
  }
  }


})

  return (
      <>
         <header className={hasFixed ?"site-header header-s1 is-sticky":"site-header header-s1 is-sticky has-fixed"}>
        <div className="navbar navbar-primary">
          <div className="container relative">
            <a className="navbar-brand" href="/">
              <img className="logo logo-dark" alt="logo" src={'images/reloIcon.jpeg'} />
              <img
                className="logo logo-light"
                alt="logo"
                src={"images/logo_white.png"}
              />
            </a>
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#mainnav"
                aria-expanded="false"
                onClick={()=>{setIsCollapse(!isCollapse)}}
              >
                <span className="sr-only">Menu</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              {/* <div className="quote-btn">
                <a className="btn" href="#contact">
                  <span>Contact</span>
                </a>
              </div> */}
            </div>
            <nav className={isCollapse?"navbar-collapse collapse ":"navbar-collapse nav-mobile collapse in"} id="mainnav">
              <ul className="nav navbar-nav">
                <li className="dropdown">
                  <a href="/" className="dropdown-toggle">
                    Home 
                    {/* <b className="caret"></b> */}
                  </a>
                  {/* <ul className="dropdown-menu">
                    <li>
                      <a href="#coHosting">Co-Hosting</a>
                    </li>
                    <li>
                      <a href="#realEstate">Real Estate</a>
                    </li>
                    <li>
                      <a href="#energy">Energy</a>
                    </li>
                    <li>
                      <a href="#mining">Mining</a>
                    </li>
                    <li>
                      <a href="#texas">Texas</a>
                    </li> 
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                {/* <li>
                  <a href="/sites">Sites</a>
                </li> */}
                {/* <li>
                  <a href="/esg">ESG</a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
        
      </header>
      
      </>
  )


}

export default Navbar;