
import "./style.css"
import "./theme.css"

function ContactUs() {



    
  return (
  
<div id="contact" class="section section-pad">
      <div class="container">
        <div class="row">
          <div class="col-md-12 res-m-bttm">
            <div class="contact-information">
              <h4>Contact Information</h4>
              <div class="row">
                <div class="col-xs-12">
                  <iframe
                    id="gmap_canvas"
                    style={{minHeight: '50vh', minWidth: '100%'}}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.2631917641434!2d-95.62527218532966!3d29.596034382047634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640e695c650ec77%3A0xc5414348d66e1793!2s2150%20Town%20Square%20Pl%20%23200%2C%20Sugar%20Land%2C%20TX%2077479%2C%20USA!5e0!3m2!1sen!2sin!4v1616562742723!5m2!1sen!2sin"
                  ></iframe>
                </div>
              </div>
              <br />
              <br />
              <div class="row">
                
                <div class="col-sm-4 res-m-bttm">
                  <div class="contact-entry">
                    <h6>Contact Number</h6>
                    <p>Mobile: 647-605-9567</p>
                  </div>
                </div>
                <div class="col-sm-4 res-m-bttm">
                  <div class="contact-entry">
                    <h6>Office Hours</h6>
                    <p>Monday - Friday<br />8:30am - 5:00pm</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-form">
              <p>Complete and submit the form below</p>
              <form
                id="contact-form"
                class="form-message"
                action="form/contact.php"
                method="post"
              >
                <div class="form-results"></div>
                <div class="form-group row">
                  <div class="form-field col-sm-6 form-m-bttm">
                    <input
                      name="contact-name"
                      type="text"
                      placeholder="Full Name *"
                      class="form-control required"
                    />
                  </div>
                  <div class="form-field col-sm-6">
                    <input
                      name="contact-email"
                      type="email"
                      placeholder="Email *"
                      class="form-control required email"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="form-field col-sm-6 form-m-bttm">
                    <input
                      name="contact-phone"
                      type="text"
                      placeholder="Phone Number *"
                      class="form-control required"
                    />
                  </div>
                  <div class="form-field col-sm-6">
                    <input
                      name="contact-subject"
                      type="text"
                      placeholder="Subject *"
                      class="form-control required"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="form-field col-md-12">
                    <textarea
                      name="contact-message"
                      placeholder="Messages *"
                      class="txtarea form-control required"
                    ></textarea>
                  </div>
                </div>
                <input
                  type="text"
                  class="hidden"
                  name="form-anti-honeypot"
                  value=""
                />
                <button type="submit" class="btn btn-alt">Submit</button>
              </form>
            </div>
          </div>
        
        </div>
      </div>
    
    </div>
 
   
  );
}

export default ContactUs;
