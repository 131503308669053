import "../shared/style.css";
import "../shared/theme.css";

const ESG = () => {
    return (
        <>
            {/* <!--Section --> */}
            <div class="section section-pad">
                <div class="container">
                    <div class="col-md ">
                        <h4 class="heading-section blueFont">Environmental, Social and Governance</h4>
                        <h2>Our commitment to building thriving, environmentally friendly blockchain infrastructure</h2>
                        <p className="custom-p">At RELO, we work at the highest integrity, curate a culture of compliance,
                            and seek out opportunities that help benefit our clients, employees, and the communities where live and operate</p>
                    </div>
                </div>
            </div>
            {/* <!--End Section --> */}

            {/* <!--Section --> */}
            <div class="section section-pad bg-grey">
                <div class="container">
                    <div class="row row-vm">
                        <div class="col-md-5 tab-center">
                            <div class="round res-m-bttm"><img src="images/environmental.jpg" alt="photo-md" /></div>
                        </div>
                        <div class="col-md-6 col-md-offset-1">
                            <div class="text-block">
                                <h2>Environmental</h2>
                                <p className="custom-p">We do our part to mine cryptocurrency sustainably, We not only look for renewable energy providers,
                                    but also invest in sustainable technology, That can be used to power our mining operations and communities worldwide.

                                </p>
                                {/* <>
                                <p className="custom-p">Here is a list of the few new green energy technologies that we have invested in</p>
                                
                                    <li >
                                        <a> LCT360</a>

                                    </li>
                                    <li>
                                        <a> Trans Energy 360</a>

                                    </li>
                                    <li>
                                        <a> GDC</a>

                                    </li>
                                    <li>
                                        <a> RELO Solar</a>

                                    </li>
                                    <li>
                                        <a> Flare Recapture Technologies</a>

                                    </li>
                                </> */}
                            </div>
                        </div>
                    </div>
                    <div class="gaps size-3x"></div>
                    <div class="row row-vm reverses">
                        <div class="col-md-5 tab-center col-md-offset-1">
                            <div class="round res-m-bttm"><img src="images/social.jpg" alt="photo-md" /></div>
                        </div>
                        <div class="col-md-6 ">
                            <div class="text-block">
                                <h2>Social</h2>
                                <p className="custom-p">Making a big impact on the world is a fundamental for us, it is at the forethought of every decision we take.
                                    Which is why we always hire local where we operate, and give back to the communities by even providing access
                                    to the internet so they can also participate in the blockchain revolution where we all linked </p>

                            </div>
                        </div>
                    </div>
                    <div class="gaps size-3x"></div>
                    <div class="row row-vm">
                        <div class="col-md-5 tab-center">
                            <div class="round res-m-bttm"><img src="images/governance.jpg" alt="photo-md" /></div>
                        </div>
                        <div class="col-md-6 col-md-offset-1">
                            <div class="text-block">
                                <h2>Governance</h2>
                                <p className="custom-p">All of our board of directors and employees share the same values. Our decisions are based on how to ensure win-win situations.
                                    We make strides to improve our processes, and give a chance to all of stakeholders to have chance to participate the shape of company, and future.
                                </p>
                                {/* <p className="custom-p">Here is a list of the few new green energy technologies that we have invested in</p> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--End Section --> */}





        </>
    );
};

export default ESG;