import "../shared/style.css";
import "../shared/theme.css";
// import "~react-image-gallery/styles/css/image-gallery.css";

import ContactUs from "../shared/ContactUs";

function Event() {
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  return (
    <div>
      <div id="header" className="banner-md light bannerTheme ">
        <img
          src="images/intro-bg.jpg"
          style={{ width: "100%",height:'100%'  }}
        />

        <div className="overlay">
        
        </div>

        <div className="headerContainer">
          <h1 className="customHeader" style={{ color: "white" }}>
            RELO Presents
          </h1>
          <h3 style={{ fontSize: "1.5em", color: "white" }}>
            Most Exclusive Investor event
          </h3>
        </div>
      </div>

      <div className="features-box section  CREM cremDescription" style={{marginTop:'50px'}}>
        <div className="row" style={{fontSize:'2em'}}>
          <div className="col-md-5   text-center">
            <h1>About this event</h1>
            <p className="cremtexDescription " >
              RELO is the world's largest mining infrastructure provider, and
              we're hosting an invite-only event for investors who can be a part
              of the next big thing. This event is all about closing deals. This
              event is ideal for investors who want to invest in investing in
              exponentially scalable business.
            </p>
          </div>
          <div className="col-md-3  text-center" >
            <h1>Where</h1>
            <p >2150, Town Square Pl, Sugar Land, Texas</p>
          </div>
          <div className="col-md-3   text-center">
            <h1>When</h1>
            <p>
              12:30 PM - 3:00 PM on 18th December 2021 <br />
              12:30 PM - 3:00 PM on 21st December 2021{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="section col-md-12 ">
        <div class="container">
          <div class="section-head">
            <div class="  text-center">
              
               
                <h2 class="heading-section p-20">Notable Attendees</h2>
              
            </div>
          </div>
          {/* <div class="gaps size-3x"></div> */}
          <div class="row text-center">
            <div class="col-md-4  col-sm-8  res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/jayHao.JPG"
                    alt="team-thumb"
                  />
                </div>
                <div class="team-info">
                  <h5 class="team-name">Jay Hao</h5>
                  <span class="team-title">Chairman and Founder</span>
                </div>
              </div>
            </div>
            <div class="col-md-4  col-sm-8  res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img
                    class="teamImg"
                    src="images/team/jay.png"
                    alt="team-thumb"
                  />
                </div>
                <div class="team-info">
                  <h5 class="team-name">Jay Patel</h5>
                  <span class="team-title">CEO and Founder</span>
                </div>
              </div>
            </div>

            <div class="col-md-4  col-sm-8  res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img  class="teamImg" src="images/team/don.jpeg" alt="team-thumb" />
                </div>
                <div class="team-info">
                  <h5 class="team-name">Don Janssen</h5>
                  <span class="team-title">Partner</span>
                </div>
              </div>
            </div>

            {/* <div class="col-md-3 col-md-offset-0 col-sm-8 col-sm-offset-2 res-m-bttm">
              <div class="team-member">
                <div class="team-thumb">
                  <img src="images/team/don.jpeg" alt="team-thumb" />
                </div>
                <div class="team-info">
                  <h5 class="team-name">Kirti Patel</h5>
                  <span class="team-title">CEO LCT360</span>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>

      <div className="col-md-12 section section-pad " >
        <h2 class=" text-center">EVENT SCHEDULE </h2>
        <div  style={{ alignContent: "center",fontSize:'2em' , textAlign:'center' }}>

        <p>12:30 -12:45 PM - Introduction of RELO by Jay Patel/Jay Hao</p>
        <p>12:45 -1:00 PM - Introduction on what kind of event this is (Parth/Don)</p>
        <p>1:00 - 2:00 PM - Q&A / Informal Round-Table Discussions</p>
        <p>2:00 - 3:00 PM - Deal Making & High Tea</p>


          {/* <div className="row">
            <div className="col-md-4">12:30 -12:45 PM</div>
            <div className="col-md-1">
              <p>Introduction of RELO by Jay Patel/Jay Hao</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-1">12:45 -1:00 PM</div>
            <div className="col-md-5">
              Introduction on what kind of event this is, (Parth/Don)
            </div>
          </div>

          <div className="row">
            <div className="col-md-1">1:00 - 2:00 PM</div>
            <div className="col-md-5">
              Q&A / Informal Round-Table Discussions
            </div>
          </div>

          <div className="row">
            <div className="col-md-1">2:00 - 3:00 PM</div>
            <div className="col-md-5">Deal Making & High Tea</div>
          </div> */}
        </div>
      </div>

      {/* <div className="container-fluid" style={{textAlign:'center', padding:'100px'}}>
          <h2 style={{textAlign:'center'}}>EVENT Venue  </h2>
          <iframe src="https://maps.google.com/maps?q=2150,%20Town%20Square%20Pl,%20Sugar%20Land,%20Texas&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" style={{border:"0",width:'100%',height:'100%'}} allowfullscreen=""></iframe>
      </div> */}

      {/* <div className="container-fluid">
        <h2 style={{ textAlign: "center" }}>Check out our gallery </h2>

        <ImageGallery items={images} />
      </div> */}

      <div className="container-fluid">
        <ContactUs />
      </div>
    </div>
  );
}

export default Event;
